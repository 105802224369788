import React, { useState, useEffect } from "react";
import style from "./style.module.css";
import conditional from "../../utils/conditional";

import NavigationView from "../ContentView";
import FeedView from "../FeedView";
import icons from "../../resources/icons";
import groups from "../../api/groups";
import { useEffectGroup, useAppState } from "../../contexts/AppState";
import ConfirmDialog from "../ConfirmDialog";
import ProfileView from "../ProfileView";
import HighFiveDialog from "../HighFiveDialog";
import AwardView from "../AwardView";
import Loader from "../v2/LoaderInline/LoaderInline";

const ContactsList = (props) => {
  const [items, setItems] = useState([]);
  const [searchedItems, setSearchedItems] = useState([]);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState(null);
  const [dialog, setDialog] = useState(null);
  const [profile, setProfile] = useState(null);
  const [award, setAward] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { auth, group } = useAppState();

  const actions = [];

  const fetch = async (group) => {
    setIsLoading(true);
    const members = await groups.members(group);

    if (!members) {
      console.log("error fetching members");
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    setItems(
      members.filter((member) => member._id !== ((auth || {}).user || {})._id)
    );
  };

  useEffectGroup(fetch);

  const select = (index) => {
    setProfile(search.length ? searchedItems[index] : items[index]);
    setSelected(index);
  };

  const wait = (time) =>
    new Promise((resolve) =>
      setTimeout(() => {
        resolve(time);
      }, time)
    );

  const highFive = (user) => {
    console.log("highFive");
    console.log(user);
    setAward(user);
  };

  const size = (thumbnail) => {
    if (thumbnail) return {};

    return {
      backgroundSize: "32px 32px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    };
  };

  const item = (item, index) => (
    <div
      key={index}
      className={conditional("item", style, { selected: selected === index })}
      onClick={() => select(index)}
    >
      <div className={style.itemContent}>
        <div className={style.itemInfo}>
          <div
            className={style.itemPhoto}
            style={{
              backgroundImage: `url(${
                item.thumbnail ||
                "https://cdn2.hubspot.net/hub/6444014/hubfs/PuzzleHR_October2019/images/Puzzle_favicon-150x150.png?width=108&height=108"
              })`,
              ...size(item.thumbnail),
            }}
          />
          <div className={style.itemNameContent}>
            <div className={style.itemName}>
              {index + 1}. {item.firstName + " " + item.lastName}{" "}
              <span className={style.emailMuted}>({item.email})</span>
            </div>
            <div className={style.itemPreview}>{item.groupData?.title}</div>
            {/* <div className={style.itemActions}>
                            <div className={style.itemAction} onClick={(e) => {e.stopPropagation();e.preventDefault(); highFive(item)}}>
                                <div className={style.itemActionIcon} style={{ backgroundImage: icons.highFive }} />
                                <div className={style.itemActionTitle}>High Five</div>
                            </div>
                            <div className={style.itemActionSecondary}>
                                <div className={style.itemActionIcon} />
                                <div className={style.itemActionTitle}>Give Badge</div>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
    </div>
  );

  const updateSearch = (value) => {
    if (!value.length) return;
    setSearch(value);
    setSearchedItems(
      items.filter((item) =>
        (item.firstName + "" + item.lastName)
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    );
  };

  useEffect(() => {
    console.log(items);
  }, [items]);
  return (
    <div className={style.FeedList}>
      {isLoading ? (
        <div className="p-[50px]">
          <Loader text="Loading your contacts..." />
        </div>
      ) : (
        <>
          <NavigationView primary title="Team" actions={actions}>
            <div className={style.items}>
              <input
                className={style.input}
                placeholder="Search"
                onChange={(e) => updateSearch(e.target.value)}
              ></input>
              {(search.length ? searchedItems : items)
                .sort((a, b) =>
                  (
                    a.firstName.toLowerCase() +
                    "" +
                    a.lastName.toLowerCase()
                  ).localeCompare(
                    b.firstName.toLowerCase() + "" + b.lastName.toLowerCase()
                  )
                )
                .map(item)}
            </div>
          </NavigationView>
          <HighFiveDialog data={dialog} />
          <ProfileView
            data={profile}
            onBack={() => {
              setProfile(null);
              setSelected(null);
            }}
          />
          <AwardView award={award} onBack={() => setAward(null)} />
        </>
      )}
    </div>
  );
};

export default ContactsList;
