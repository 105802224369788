import React, { useEffect, useRef, useState } from "react";
import style from "./style.module.css";

import NavigationView from "../ContentView";
import conditional from "../../utils/conditional";
import icons from "../../resources/icons";
import groups from "../../api/groups";
import auth from "../../api/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserLock,
  faBuildingUser,
  faPlus,
  faBuildingLock,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import Loader from "../v2/LoaderInline/LoaderInline";
import Select from "react-select";

const PuzzleAdminView = (props) => {
  const [current, setCurrent] = useState(null);

  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState([]);
  const [unassigningOrganizations, setUnassigningOrganizations] = useState([]);

  const [scroll, setScroll] = useState(null);
  const [user, setUser] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const backIdentifier = useRef(null);
  const previous = useRef(null);

  const [organizationIdNameMap, setOrganizationIdNameMap] = useState({});

  useEffect(() => {
    if (!props.data) return;
    setCurrent(props.data._id);
  }, [props.data]);

  const fetchData = async () => {
    setIsLoading(true);

    const userData = await auth.getUserData(current);
    setUser(userData.user);

    const groupItems = await groups.fetch();
    let filteredGroupItems = groupItems;

    if (userData.user.assignedOrganizations.length > 0) {
      filteredGroupItems = groupItems.filter(
        (i) => !userData.user.assignedOrganizations.includes(i._id)
      );
    }

    setItems(filteredGroupItems.map((i) => ({ value: i._id, label: i.name })));

    const mapItems = groupItems.reduce((mapObject, item) => {
      mapObject[item._id] = item.name;
      return mapObject;
    }, {});

    setOrganizationIdNameMap(mapItems);

    setIsLoading(false);
    if (!groupItems) {
      console.log("error fetching group");
      return;
    }
  };

  useEffect(() => {
    if (!current) return;
    async function fetch() {
      await fetchData();
    }

    fetch();
  }, [current]);

  async function assignUser() {
    setIsLoading(true);
    const groupIds = JSON.stringify(selected.map((i) => i.value));
    await auth.assignPuzzler(groupIds, user._id);
    await fetchData();
    setSelected([]);
  }

  async function unassignUser() {
    setIsLoading(true);
    const groupIds = JSON.stringify(unassigningOrganizations);
    await auth.assignPuzzler(groupIds, user._id);
    await fetchData();
    setUnassigningOrganizations([]);
  }

  const handleUnassigningOrganizationsChange = (event) => {
    const { value, checked } = event.target;

    setUnassigningOrganizations((prevSelected) =>
      checked
        ? [...prevSelected, value]
        : prevSelected.filter((id) => id !== value)
    );
  };

  const wait = (time) =>
    new Promise((resolve) =>
      setTimeout(() => {
        resolve();
      }, time)
    );

  const back = async () => {
    props.onBack();

    const chat = current;
    const identifier = Math.random().toString(36);
    backIdentifier.current = identifier;

    await wait(500);

    if (current !== chat || backIdentifier.current !== identifier) return;
    setCurrent(null);
    previous.current = null;
    setItems([]);
  };

  const organizationViewClass = conditional("UserView", style, {
    visible: props.data !== null,
  });

  if (!current) return <div className={organizationViewClass} />;

  const actions = [
    {
      icon: icons.edit,
      perform: () => setUser(props.data),
    },
  ];

  function handleSelected(selected) {
    setSelected(selected || []); // Ensures state is never null
  }

  return (
    <div className={organizationViewClass}>
      <NavigationView
        secondary
        title={props.data.firstName + " " + props.data.lastName}
        bottom
        scroll={scroll}
        onBack={back}
        actions={actions}
      >
        <div className="p-5">
          {isLoading && <Loader text="Loading admin data..." />}

          {!isLoading && user && user.superAdmin ? (
            <p className="mb-4 text-xs flex gap-2">
              <FontAwesomeIcon
                className="text-sm text-slate-500"
                icon={faInfoCircle}
              />
              This user is a superadmin and has access to all organizations.
            </p>
          ) : (
            !isLoading && ( // ✅ Hide section while loading
              <div>
                <p className="text-sm flex gap-2 mb-5 items-center">
                  <FontAwesomeIcon
                    className="text-slate-800"
                    icon={faUserLock}
                  />
                  <span>Assign Puzzler to Organization:</span>
                </p>

                <div className="inline-flex items-start">
                  <Select
                    className="w-[300px] text-xs"
                    options={items}
                    placeholder="Start typing..."
                    disabled={isLoading}
                    onChange={handleSelected}
                    isMulti
                  ></Select>

                  <button
                    disabled={isLoading}
                    onClick={() => assignUser()}
                    className="px-2 flex gap-2 items-center text-xs bg-slate-800 py-3 text-white"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    <FontAwesomeIcon icon={faBuildingUser} />
                    Assign
                  </button>
                </div>

                <div className="flex gap-2 items-center  mt-[50px]">
                  <p className="text-sm flex gap-2 items-center">
                    <FontAwesomeIcon
                      className="text-slate-800"
                      icon={faBuildingLock}
                    />
                    <span>Assigned Organizations:</span>
                  </p>
                  {unassigningOrganizations.length > 0 && (
                    <button
                      onClick={unassignUser}
                      className="bg-rose-500 text-white text-xs p-3"
                    >
                      <FontAwesomeIcon icon={faTimes} />{" "}
                      <FontAwesomeIcon icon={faBuildingUser} /> Unassign{" "}
                      {unassigningOrganizations.length} organization
                      {unassigningOrganizations.length > 1 ? "s" : ""}
                    </button>
                  )}
                </div>
                <div className="flex flex-col gap-3 mt-5">
                  {user &&
                    user.assignedOrganizations.length > 0 &&
                    user.assignedOrganizations.map((organizationId) => (
                      <p
                        key={organizationId}
                        className="text-xs text-gray-800 items-center flex gap-5"
                      >
                        <label
                          className="flex gap-2 items-center"
                          for={organizationId}
                        >
                          <input
                            id={organizationId}
                            type="checkbox"
                            value={organizationId}
                            name="unassigningOrganizations"
                            className="scale-105"
                            checked={unassigningOrganizations.includes(
                              organizationId
                            )}
                            onChange={handleUnassigningOrganizationsChange}
                          />
                          <span className="font-bold">
                            {organizationIdNameMap[organizationId]}
                          </span>
                        </label>
                      </p>
                    ))}
                </div>
              </div>
            )
          )}
        </div>
      </NavigationView>
    </div>
  );
};

export default PuzzleAdminView;
