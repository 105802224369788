import React, { useEffect, useState }from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faPaperPlane, faTrash, faSave } from '@fortawesome/free-solid-svg-icons'
import notes from '../../../api/notes'
import { useAppState } from '../../../contexts/AppState'
import Loader from '../LoaderInline/LoaderInline'


export default function CreatePublicNote({ onClose, toUser, type, quoteNoteMode, editData = {content: "", noteId: ""}}) {

  const [content, setContent] = useState(quoteNoteMode === "edit" ? editData.content : "")
  const [isLoading, setIsLoading] = useState(false);


  const { group } = useAppState()

  async function handleSubmit() {
    setIsLoading(true)
    if (quoteNoteMode === 'create') {
      await notes.create(group, toUser, content, type)
      setIsLoading(false)
      return onClose({refreshData: true});
    } else if (quoteNoteMode === 'edit') {
      await notes.edit(editData.noteId, content)
      setIsLoading(false)
      return onClose({refreshData: true});
    }
  }

  async function handleDelete() {
    setIsLoading(true)
    await notes.removeNote(editData.noteId)
    setIsLoading(false)
    return onClose({refreshData: true});
  }

  return (
    <div className="bg-gray-800/20 fixed top-0 left-0 w-[100%] flex items-center justify-center h-[100vh]">
          <div className="bg-white p-5 rounded-lg overflow-auto h-max max-h-[500px] w-[50vw] max-md:w-[95vw] max-md:h-[90vh] relative">
            <FontAwesomeIcon
              onClick={() => onClose({refreshData: false})}
              className="text-rose-600 hover:scale-[1.2] duration-200 text-lg font-bold cursor-pointer absolute right-[20px] top-[20px]"
              icon={faTimes}
            />
            <p className="text-lg font-bold text-slate-800">{quoteNoteMode === "edit" ? "Edit content" : type === "public" ? "Recognize Employee" : "Create a Note"}</p>
            <div className="flex items-baseline flex-col mt-5 gap-2">
              <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
                rows="5"
                className="p-3 border w-full border-slate-400 rounded-md focus:border-slate-600 outline-none max-md:h-[60vh]"
              ></textarea>
              {isLoading ? <Loader text="Please wait..." /> : <div className='flex gap-2 w-full'>
              {
                quoteNoteMode === "edit" && <button onClick={handleDelete} className="rounded-md py-2 px-5 text-xs text-rose-600 border border-rose-600 ">
                <FontAwesomeIcon icon={faTrash} /> Delete
              </button>
              }
              <button onClick={handleSubmit} className=" rounded-md py-2 px-5 text-xs text-white bg-lime-600">
                <FontAwesomeIcon icon={faSave} /> {quoteNoteMode === "edit" ? "Update" : "Save"}
              </button></div>}
            </div>
          </div>
        </div>
  )
}
