import React, { useState, useRef, useEffect } from "react";
import style from "./style.module.css";
import { awardIcon } from "../../resources/icons";

import NavigationView from "../ContentView";
import { useAppState, useEffectGroup } from "../../contexts/AppState";
import notes from "../../api/notes";
import badges from "../../api/badges";
import conditional from "../../utils/conditional";
import AwardView from "../AwardView";
import date from "../../utils/date";
import Loader from "../v2/LoaderInline/LoaderInline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faEyeSlash,
  faPaperPlane,
  faPen,
  faTimes,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import apiAuth from "../../api/auth";
import { faCommenting, faHand } from "@fortawesome/free-regular-svg-icons";
import CreatePublicNote from "../v2/CreatePublicNote/CreatePublicNote";

const actions = [];

const ProfileView = (props) => {
  const { auth, group, setAuth } = useAppState();

  const [current, setCurrent] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const [awards, setAwards] = useState([]);
  const [earned, setEarned] = useState([]);
  const [compliments, setCompliments] = useState([]);
  const [dialog, setDialog] = useState(null);

  const [isUploadingProfilePhoto, setIsUploadingProfilePhoto] = useState(false);
  const [isClearingProfilePhoto, setIsClearingProfilePhoto] = useState(false);
  const [userProfilePhoto, setUserProfilePhoto] = useState(null);
  const [profileUser, setProfileUser] = useState(null);
  const [profileData, setProfileData] = useState(null);

  const [isQuoteAddOpen, setIsQuoteAddOpen] = useState(false);
  const [isNoteAddOpen, setIsNoteAddOpen] = useState(false);

  const [isAdmin, setIsAdmin] = useState(false);

  const [asManager, setAsManager] = useState(false);

  const [notesList, setNotesList] = useState([]);

  const [quoteNoteMode, setQuoteNoteMode] = useState("create"); // create | edit
  const [editData, setEditData] = useState({
    content: "",
    noteId: "",
  });

  const [noteType, setNoteType] = useState("public");

  const fileInputRef = useRef(null);
  const [error, setError] = useState(null);
  const [preview, setPreview] = useState(null);

  // Allowed file types
  const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
  const maxSize = 5 * 1024 * 1024; // 5MB

  // Open file manager when clicking the div
  const handleUploaderWrapperClick = () => {
    fileInputRef.current.click();
  };

  // Handle file selection
  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (!file) return;

    // Validate file type
    if (!allowedTypes.includes(file.type)) {
      setError("Only PNG, JPEG, and JPG files are allowed!");
      return;
    }

    // Validate file size
    if (file.size > maxSize) {
      setError("File size must be less than 5MB!");
      return;
    }

    setError(null); // Clear errors if validation passes

    // Show preview of selected image
    const reader = new FileReader();
    reader.onload = () => setPreview(reader.result);
    reader.readAsDataURL(file);

    setIsUploadingProfilePhoto(true);
    // Upload the file
    try {
      const response = await apiAuth.uploadProfilePicture(file);
      if (response && response.updatedUser) {
        setAuth({ ...auth, user: response.updatedUser });
      }
      return setIsUploadingProfilePhoto(false);
    } catch (error) {
      console.error("Upload failed:", error);
      setError("Upload failed. Please try again.");
      return setIsUploadingProfilePhoto(false);
    }
  };

  const clearProfilePhoto = async () => {
    setIsClearingProfilePhoto(true);
    const response = await apiAuth.update(auth.user._id, { photo: "" });
    if (response.user) {
      setAuth({ ...auth, user: { ...auth.user, photo: response.user.photo } });
    }
    setIsClearingProfilePhoto(false);
  };

  const backIdentifier = useRef(null);
  const previous = useRef(null);
  const groupRef = useRef(null);

  const fetch = async (group, reload = true) => {
    groupRef.current = group;

    setAwards([]);
    setEarned([]);
    setCompliments([]);

    await fetchUserData();
  };

  function filterBadgesByHighestOrder(items) {
    // Define the order hierarchy
    const orderPriority = { Gold: 3, Silver: 2, Bronze: 1 };

    // Create a map to store the highest priority items by name
    const highestOrderMap = {};

    // Iterate through the items
    items.forEach((item) => {
      const {
        name,
        secondaryInfo: { description },
      } = item;

      // Check if there's already an entry for this name in the map
      if (
        !highestOrderMap[name] ||
        orderPriority[description] >
          orderPriority[highestOrderMap[name].secondaryInfo.description]
      ) {
        highestOrderMap[name] = item;
      }
    });

    // Return the filtered items as an array
    return Object.values(highestOrderMap);
  }

  const fetchUserData = async () => {
    setIsFetching(true);
    const user = current ? current : props.root && auth ? auth.user : null;
    if (!user) return;

    const result = await notes.list(user._id);
    setNotesList(result);

    const [awarded] = await Promise.all([badges.awarded(group, user._id)]);

    if (!awarded) {
      console.log("error fetching awards", awarded);
      setIsFetching(false);
      return;
    }

    setAwards(awarded);
    setIsFetching(false);
  };

  useEffect(() => {
    if (!current) return;
    setDialog(null);
    const getResponse = async () => {
      await fetchUserData();
    };

    getResponse();
  }, [current]);

  useEffectGroup(fetch); // for badges

  useEffect(() => {
    if (!props.data) return;
    setCurrent(props.data);

    const identifier = Math.random().toString(36);
    backIdentifier.current = identifier;

    const getResponse = async () => {
      // await fetch(group);
    };

    getResponse();
  }, [props.data]);

  const wait = (time) =>
    new Promise((resolve) =>
      setTimeout(() => {
        resolve();
      }, time)
    );

  const back = async () => {
    props.onBack();

    const profile = current;
    const identifier = Math.random().toString(36);
    backIdentifier.current = identifier;

    await wait(500);

    if (
      (current && profile && current._id !== profile._id) ||
      backIdentifier.current !== identifier
    )
      return;
    setCurrent(null);
    previous.current = null;

    setAwards([]);
    setEarned([]);
  };

  const user = () => {
    if (!auth || !auth.user) return {};
    return auth.user;
  };

  const data = () => {
    const groups = user().groups || [];
    if (!groups.length) return {};

    const active = groups.filter((data) => data._id === group)[0];
    if (!active) return {};

    return active;
  };

  const finish = () => {
    fetchUserData();
    setDialog(null);
  };

  const signOut = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("at");
    window.location.reload();
  };

  const award = (item, index) => (
    <div
      key={index}
      className={conditional("awardItem", style, { selected: false })}
    >
      <div className={style.awardItemContent}>
        <div
          className={style.awardItemPhoto}
          style={{ backgroundImage: awardIcon(item.name) }}
        />
        <div className={style.awardItemInfo}>
          <div className={style.awardItemNameContent}>
            <div className={style.awardItemName}>{item.name}</div>
            <div className={style.awardItemDate}>{item.count}x</div>
          </div>
          {/* <div className={style.awardItemPreview}>{item.secondaryInfo.description || 'Serving customers'}</div> */}
        </div>
      </div>
    </div>
  );

  const postItem = (item) => (
    <div
      className={conditional("postItem", style, { selected: false })}
      onClick={() => {}}
    >
      <div className={style.postItemContent}>
        <div className={style.postItemInfo}>
          <div className={style.postItemPhoto}>
            {item.user.photo ? (
              <img className="object-cover" src={item.user.photo} />
            ) : null}
          </div>
          <div className={style.postItemNameContent}>
            <div className={style.postItemNameContainer}>
              <div className={style.postItemName}>
                {item.user.firstName} {item.user.lastName}
              </div>
              <div className={style.postItemDate}>{date.format(item.date)}</div>
            </div>
            <div className={style.postItemPreview}>{item.content}</div>
            {item.image ? (
              <div className={style.postItemMedia}>
                <img src={item.image} />
              </div>
            ) : null}
            {/* <div className={style.itemDate}>{item.title}</div> */}
          </div>
        </div>

        {/* <div className={style.itemActions}>
                    <div className={style.itemShare}>Share</div>
                </div> */}
      </div>
    </div>
  );

  const compliment = (item, index) => (
    <div className={conditional("awardItem", style, { selected: false })}>
      {JSON.stringify(item)}

      <div className={style.awardItemContent}>
        <div className={style.awardItemInfo}>
          <div className={style.awardItemNameContent}>
            <div className={style.awardItemName}>
              {item.user.firstName + " " + item.user.lastName}
            </div>
            <div className={style.awardItemDate}>{item.count}x</div>
          </div>
        </div>
        {/* <div className={style.awardItemPhoto} style={{ backgroundImage: awardIcon(item.name) }} />
                <div className={style.awardItemInfo}>
                    <div className={style.awardItemNameContent}>
                        <div className={style.awardItemName}>{item.name}</div>
                        <div className={style.awardItemDate}>{item.count}x</div>
                    </div>
                </div> */}
      </div>
    </div>
  );

  const badge = (item, index) => (
    <div className={conditional("badgeItem", style, { selected: false })}>
      <div className={style.badgeItemContent}>
        <div
          className={style.badgeItemPhoto}
          style={{ backgroundImage: awardIcon(item.name) }}
        />
        <div className={style.badgeItemInfo}>
          <div className={style.badgeItemNameContent}>
            <div className={style.badgeItemName}>{item.name}</div>
            {/* <div className={style.badgeItemDate}>{(item.secondaryInfo || {}).description}</div> */}
          </div>
          <div className={style.badgeItemPreview}>
            {(item.secondaryInfo || {}).description}
          </div>
        </div>
      </div>
    </div>
  );

  const profileViewClass = conditional("ProfileView", style, {
    visible: props.data || props.root,
    secondary: !props.root,
  });

  useEffect(() => {
    setProfileUser(current ? current : props.root ? user() : {});
    setProfileData(
      current
        ? { ...data(), data: current.groupData }
        : props.root
        ? data()
        : {}
    );
  }, [current]);

  useEffect(() => {
    setUserProfilePhoto(auth.user.photo);
    setIsAdmin(auth.user.admin);
  }, [auth]);

  if (!profileUser || !profileData) return;

  if (!Object.keys(profileUser).length)
    return <div className={profileViewClass} />;

  return (
    <div className={profileViewClass}>
      <NavigationView
        secondary={!props.root}
        title="Profile"
        onBack={!props.root ? back : null}
        hideHeader
        actions={actions}
      >
        <div className={style.profile}>
          <div className={style.itemPhoto}>
            {userProfilePhoto && (
              <img
                alt="user profile"
                className="object-cover"
                src={
                  current && current.photo.length
                    ? current.photo
                    : userProfilePhoto
                }
              />
            )}
          </div>
          {!current && (
            <div className="flex items-center gap-2">
              {auth.user.photo.length > 0 ? (
                <p
                  onClick={
                    isUploadingProfilePhoto ? () => {} : clearProfilePhoto
                  }
                  className="text-xs cursor-pointer hover:bg-slate-200 inline-flex items-center gap-2 mt-5 bg-slate-100 px-3 py-2 rounded-md"
                >
                  <FontAwesomeIcon
                    className={`${
                      isClearingProfilePhoto ? "animate-spin" : ""
                    }`}
                    icon={isClearingProfilePhoto ? faCircleNotch : faTimes}
                  />
                  {isClearingProfilePhoto
                    ? "Clearing Profile Photo..."
                    : "Clear"}
                </p>
              ) : null}

              <p
                onClick={
                  isUploadingProfilePhoto
                    ? () => {}
                    : handleUploaderWrapperClick
                }
                className="text-xs cursor-pointer hover:bg-slate-200 inline-flex items-center gap-2 mt-5 bg-slate-100 px-3 py-2 rounded-md"
              >
                <FontAwesomeIcon
                  className={`${isUploadingProfilePhoto ? "animate-spin" : ""}`}
                  icon={isUploadingProfilePhoto ? faCircleNotch : faUpload}
                />
                {isUploadingProfilePhoto
                  ? "Uploading New Profile Photo..."
                  : "Change Profile Photo"}
              </p>
            </div>
          )}

          {error && (
            <p
              onClick={() => setError(null)}
              className="text-xs cursor-pointer text-rose-500 mt-2"
            >
              <FontAwesomeIcon icon={faTimes} /> {error}
            </p>
          )}

          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            accept="image/png, image/jpeg, image/jpg"
            className="hidden"
          />
          <div
            className={style.itemName}
          >{`${profileUser.firstName} ${profileUser.lastName}`}</div>
          <div
            className={style.itemTitle}
            style={{
              opacity: ((profileData || {}).data || {}).title ? "1.0" : "0.0",
            }}
          >{`${((profileData || {}).data || {}).title} at ${
            profileData.name
          }`}</div>
        </div>
        {props.root ? null : (
          <div className={style.actions}>
            <button
              onClick={() => {
                setAsManager(false);
                setDialog(current);
              }}
              className="bg-lime-600 rounded-md py-2 px-5 text-xs text-white"
            >
              <FontAwesomeIcon icon={faHand} /> High Five as Colleague
            </button>
          </div>
        )}

        {isFetching ? (
          <div className="p-5">
            <Loader align="left" text="Loading user data..." />
          </div>
        ) : (
          <div className={style.section}>
            {/* Colleague High Fives */}
            <div className="text-sm font-bold text-gray-500">
              Colleague High Fives
            </div>
            <div className={style.section}>
              {isFetching ? (
                <Loader align="left" text="Loading high fives..." />
              ) : (
                <div
                  className={style.sectionContent}
                  style={{ paddingLeft: "0px", paddingRight: "0px" }}
                >
                  <div className={style.awardItems}>
                    {awards.colleagueBadges && awards.colleagueBadges.length ? (
                      awards.colleagueBadges.map(award)
                    ) : (
                      <p className="text-xs">No High Fives.</p>
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* Manager High Fives */}
            <div className="mt-[50px]">
              <div className="flex gap-2 items-center max-md:flex-col max-md:items-start">
                <div className="text-sm font-bold text-gray-500">
                  Manager High Fives
                </div>
                {isAdmin && (
                  <button
                    onClick={() => {
                      setAsManager(true);
                      setDialog(current);
                    }}
                    className="ml-auto max-md:ml-0 border border-slate-800 rounded-md py-2 px-5 text-xs text-slate-800 hover:text-white hover:bg-slate-800"
                  >
                    <FontAwesomeIcon icon={faHand} /> High Five as Manager
                  </button>
                )}
              </div>
              <div className={style.section}>
                {isFetching ? (
                  <Loader align="left" text="Loading high fives..." />
                ) : (
                  <div
                    className={style.sectionContent}
                    style={{ paddingLeft: "0px", paddingRight: "0px" }}
                  >
                    <div className={style.awardItems}>
                      {awards.managerBadges && awards.managerBadges.length ? (
                        awards.managerBadges.map(award)
                      ) : (
                        <p className="text-xs">No High Fives.</p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Manager Recognitions */}
            <div className="mt-[50px]">
              <div className="flex gap-2 items-center max-md:flex-col max-md:items-start">
                <div className="text-sm font-bold text-gray-500">
                  Manager Recognitions
                </div>
                {isAdmin && (
                  <button
                    onClick={() => {
                      setIsQuoteAddOpen(true);
                      setNoteType("public");
                    }}
                    className="ml-auto max-md:ml-0 border border-slate-800 rounded-md py-2 px-5 text-xs text-slate-800 hover:text-white hover:bg-slate-800"
                  >
                    <FontAwesomeIcon icon={faCommenting} /> Recognize Employee
                  </button>
                )}
              </div>

              {/* Quotes */}
              <div className="mt-5 flex flex-col gap-3 px-5">
                {isFetching ? (
                  <Loader align="left" text="Loading manager quotes..." />
                ) : (
                  <>
                    {notesList && notesList.length
                      ? notesList
                          .filter((note) => note.type === "public")
                          .map((note, index) => (
                            <div
                              key={index}
                              className="bg-lime-300/10 shadow-sm border-lime-600 border-b rounded-md p-4 flex gap-5"
                            >
                              <div className="flex flex-col gap-2">
                                <p className="text-sm text-slate-800 font-bold">
                                  {note.content}
                                </p>
                                <p className="text-xs text-slate-500">
                                  {new Date(note.timestamp).toLocaleDateString(
                                    "en-US"
                                  )}{" "}
                                  |{" "}
                                  <span className="text-slate-600">
                                    {note.managerData.firstName || ""}{" "}
                                    {note.managerData.lastName || ""}{" "}
                                  </span>
                                </p>
                              </div>
                              {note.managerData._id === auth.user._id && (
                                <div className="ml-auto flex gap-2 items-start">
                                  <button
                                    onClick={() => {
                                      setIsQuoteAddOpen(true);
                                      setQuoteNoteMode("edit");
                                      setEditData({
                                        content: note.content,
                                        noteId: note._id,
                                      });
                                    }}
                                    className="ml-auto border border-slate-800 rounded-md px-2 py-1 text-xs text-slate-800 hover:text-white hover:bg-slate-800"
                                  >
                                    <FontAwesomeIcon icon={faPen} />
                                  </button>
                                </div>
                              )}
                            </div>
                          ))
                      : null}
                  </>
                )}
              </div>
            </div>

            {/* Direct Notes */}
            <div className="mt-[50px]">
              <div className="flex gap-2 items-center max-md:flex-col max-md:items-start">
                <div className="text-sm font-bold text-gray-500">
                  Direct Notes
                </div>
                {isAdmin && (
                  <>
                    <button
                      onClick={() => {
                        setIsQuoteAddOpen(true);
                        setNoteType("private");
                      }}
                      className="ml-auto border border-slate-800 rounded-md py-2 px-5 text-xs text-slate-800 hover:text-white hover:bg-slate-800 max-md:ml-0"
                    >
                      <FontAwesomeIcon icon={faEyeSlash} /> Write a Note
                    </button>
                  </>
                )}
              </div>

              {/* Notes */}
              <div className="mt-5 flex flex-col gap-3 px-5">
                {isFetching ? (
                  <Loader align="left" text="Loading manager quotes..." />
                ) : (
                  <>
                    {notesList && notesList.length
                      ? notesList
                          .filter(
                            (note) =>
                              note.managerData._id === auth.user._id &&
                              note.type === "private"
                          )
                          .map((note, index) => (
                            <div
                              key={index}
                              className="bg-slate-800/10 shadow-sm border-slate-800 border-b rounded-md p-4 flex gap-5"
                            >
                              <div className="flex flex-col gap-2">
                                <p className="text-sm text-slate-800 font-bold">
                                  {note.content}
                                </p>
                                <p className="text-xs text-slate-500">
                                  {new Date(note.timestamp).toLocaleDateString(
                                    "en-US"
                                  )}{" "}
                                  |{" "}
                                  <span className="text-slate-600">
                                    {note.managerData.firstName || ""}{" "}
                                    {note.managerData.lastName || ""}{" "}
                                  </span>
                                </p>
                              </div>
                              {note.managerData._id === auth.user._id && (
                                <div className="ml-auto flex gap-2 items-start">
                                  <button
                                    onClick={() => {
                                      setIsQuoteAddOpen(true);
                                      setQuoteNoteMode("edit");
                                      setEditData({
                                        content: note.content,
                                        noteId: note._id,
                                      });
                                    }}
                                    className="ml-auto border border-slate-800 rounded-md px-2 py-1 text-xs text-slate-800 hover:text-white hover:bg-slate-800"
                                  >
                                    <FontAwesomeIcon icon={faPen} />
                                  </button>
                                </div>
                              )}
                            </div>
                          ))
                      : null}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </NavigationView>
      <AwardView
        asManager={asManager}
        award={dialog}
        onBack={() => {
          setDialog(null);
          setAsManager(false);
        }}
        onFinish={finish}
      />
      {isQuoteAddOpen && (
        <CreatePublicNote
          toUser={current._id}
          type={noteType}
          quoteNoteMode={quoteNoteMode}
          editData={quoteNoteMode === "edit" ? editData : ""}
          onClose={({ refreshData }) => {
            setIsQuoteAddOpen(false);
            setQuoteNoteMode("create");
            setEditData({
              content: "",
              noteId: "",
            });
            setNoteType("public");

            if (refreshData) {
              fetch();
            }
          }}
        />
      )}
      <div></div>
    </div>
  );
};

export default ProfileView;
