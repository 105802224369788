import { component } from "./";
import bridge from "bridge-request";
import axios from "axios";

const request = component("/auth");

const fetch = async () => {
  try {
    const result = await request("/fetch", {});
    // await new Promise(resolve => setTimeout(resolve, 5000));
    return result;
  } catch (e) {
    return null;
  }
};

const getUserData = async (userId) => {
  try {
    const result = await request("/getUserData", { userId });
    return result;
  } catch (e) {
    return null;
  }
};

const verify = async (data) => {
  const route = data.phone ? "/verify/phone" : "/verify/email";
  const auth = data.phone ? { phone: data.phone } : { email: data.email };
  try {
    const result = await request(route, auth, false);
    console.log(result);
    return result;
  } catch (e) {
    return null;
  }
};

const signIn = async (data) => {
  const route = data.phone ? "/phone" : "/email";
  const auth = data.phone ? { phone: data.phone } : { email: data.email };

  console.log(route);
  try {
    const result = await request(route, { ...auth, code: data.code });
    if (!result.token || !result.user) {
      return {
        success: false,
        invalid: result.invalidCode,
        user: null,
      };
    }
    try {
      const data = JSON.stringify(result.user);
      localStorage.setItem("user", data);
      localStorage.setItem("at", result.token);

      const updateBridge = async () => {
        console.log("updateToken a");
        try {
          await bridge.request("updateToken", { token: result.token });
        } catch (e) {}
      };

      updateBridge();
    } catch (e) {
      console.log("error 1");
      console.log(e);
    }

    return {
      success: true,
      invalid: result.invalidCode,
      user: result.user,
    };
  } catch (e) {
    return null;
  }
};

const assignPuzzler = async (groupIds, puzzlerId) => {
  try {
    const result = await request("/assignPuzzler", { groupIds, puzzlerId });
    return result;
  } catch (e) {
    return null;
  }
};

const uploadProfilePicture = async (file) => {
  try {
    const formData = new FormData();
    formData.append("profilePicture", file);

    const response = await request("/uploadProfilePicture", formData);
    return response;
  } catch (e) {
    console.error("Upload failed:", e);
    return null;
  }
};

const update = async (user, updateObject) => {
  try {
    const result = await request("/update", { user, update: updateObject });
    return result;
  } catch (e) {
    return null;
  }
};

export const signOut = () => {
  try {
    localStorage.removeItem("user");
    localStorage.removeItem("at");
    return true;
  } catch (e) {
    return false;
  }
};

export default {
  fetch,
  verify,
  signIn,
  getUserData,
  assignPuzzler,
  uploadProfilePicture,
  update,
};
