import React, { useState, useEffect } from "react";
import style from "./style.module.css";

import { useAppState } from "../../contexts/AppState";

import Tabs from "../Tabs";
import TitleBar from "../TitleBar";

import Home from "../Home";
import ChatList from "../ChatList";
import ChatView from "../ChatView";
import ProfileView from "../ProfileView";
import ContactsList from "../ContactsList";
import Sidebar from "../Sidebar";
import SignIn from "../SignIn";
import conditional from "../../utils/conditional";
import ResponseList from "../ResponseList";
import icons from "../../resources/icons";
import OrganizationList from "../OrganizationList";
import UserList from "../UserList";
import Aggregates from "../Aggregates";
import { identifiers } from "../Home";
import surveys from "../../api/surveys";
import ShortcutsView from "../ShortcutsView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsSpin,
  faDownload,
  faRightToBracket,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { signOut } from "../../api/auth";
import Loader from "../v2/Loader/Loader";
import PuzzleAdmins from "../PuzzleAdmins/PuzzleAdmins";
import getLatestAppVersion from "../../api/appVersionApi";

const handleSignOut = () => {
  const result = signOut();
  if (result) {
    window.location.reload();
  }
};

const App = (props) => {
  const {
    auth,
    setAuth,
    path,
    setPath,
    role,
    setRole,
    keyboard,
    groupAdmin,
    group,
    appVersion,
    setAppVersion,
    isAppOutdated,
    setIsAppOutdated,
  } = useAppState();
  const [recommendations, setRecommendations] = useState([]);
  const [isAuthDataLoaded, setIsAuthDataLoaded] = useState(false);
  const [isPuzzler, setIsPuzzler] = useState(false);

  const [roleLinks, setRoleLinks] = useState([
    {
      icon: icons.profile,
      iconSelected: icons.profileSelected,
      name: "Personal",
      role: "user",
    },
  ]);

  // Todo: fordave - this is not how you build SPA - proper routing should be implemented
  // const route = (route, content) => <div>{route === path && content}</div>

  const bottomClass = conditional("bottom", style, { keyboard });

  const app = true; // window.webkit && window.webkit.messageHandlers;
  const appClass = conditional("App", style, { app });

  const categoryLink = (link) => {
    const className = conditional("roleLink", style, {
      selected: role === link.role,
    });

    return (
      <div key={link} className={className} onClick={() => setRole(link.role)}>
        <div
          className={style.roleIcon}
          style={{
            backgroundImage: role === link.role ? link.iconSelected : link.icon,
          }}
        ></div>
        <div className={style.roleName}>{link.name}</div>
      </div>
    );
  };

  const profile = () => {
    if (!auth || !auth.user) return {};
    return auth.user;
  };

  useEffect(() => {
    console.log(path);
  }, [path]);

  useEffect(() => {
    if (profile().groups && profile().groups.length) {
      setIsPuzzler(
        profile()
          .groups.map((group) => group._id)
          .includes("65406e8bf9134425b97d7a5c")
      );
    }
  }, [profile]);

  useEffect(() => {
    if (isPuzzler) {
      setRoleLinks([
        ...roleLinks,
        {
          icon: icons.controls,
          iconSelected: icons.controlsSelected,
          name: "Control Panel",
          role: "admin",
          groupAdmin: true,
        },
      ]);
    }
  }, [isPuzzler]);

  const validateAppVersion = async () => {
    const currentAppVersion = Number(appVersion);
    if (!currentAppVersion) return;

    const latestAppVersion = await getLatestAppVersion();

    if (currentAppVersion < latestAppVersion) setIsAppOutdated(true);
    localStorage.setItem("pz-app-version", latestAppVersion);
  };

  // web-app versioning workaround
  useEffect(() => {
    // Try to get saved app version, if none, set to 1
    const currentAppVersion = localStorage.getItem("pz-app-version");
    if (!currentAppVersion) {
      setAppVersion(1);
    } else {
      setAppVersion(currentAppVersion);
    }
    // Fetch the latest app version from database
  }, []);

  useEffect(() => {
    validateAppVersion();
  }, [appVersion]);

  if (isAppOutdated)
    return (
      <div className="h-[100vh] w-[100vw] bg-white flex item-center justify-center flex-col p-5 gap-3">
        <p className="text-sm text-center">
          A new version has been detected. Please update to get the latest
          features.
        </p>
        <div
          onClick={() => window.location.reload()}
          className=" bg-lime-600 p-5 rounded-md self-center text-white flex gap-2 items-center"
        >
          <FontAwesomeIcon icon={faUpload} />
          <span>Update to latest Version</span>
        </div>
      </div>
    );

  if (!auth) return <SignIn />;
  return (
    <div className={appClass}>
      <div className={style.top}>
        <div className={style.roleLinks}>
          <div className={style.brand} style={{ background: icons.icon }} />
          {roleLinks
            .filter((link) =>
              link.admin
                ? profile().admin === true
                : link.groupAdmin
                ? groupAdmin
                : true
            )
            .map(categoryLink)}
        </div>
        <div className={style.account} onClick={() => setPath("/profile")}>
          <div
            className={style.accountPhoto}
            style={{
              backgroundImage: `url(${profile().photo})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <div className={style.accountInfo}>
            <div className={style.accountName}>
              {profile().firstName} {profile().lastName}
            </div>
            <div className={style.accountGroup}>PuzzleHR</div>
          </div>
        </div>
        <div onClick={() => handleSignOut()} className={style.headerSignout}>
          <FontAwesomeIcon icon={faRightToBracket} />
          <span>Log out</span>
        </div>
        {/* <div className={style.organization}>
                    <div className={style.organizationText}>PuzzleHR</div>
                    <div className={style.organizationIcon} style={{ backgroundImage: icons.dropdown }} />
                </div> */}
      </div>
      <div className={style.main}>
        <div className={style.sidebar}>
          <Sidebar />
        </div>
        <div className={style.content}>
          {path === "/" && <Home recommendations={recommendations} />}
          {path === "/chat" && <ChatList />}
          {path === "/contacts" && <ContactsList />}
          {path === "/profile" && <ProfileView root />}
          {path === "/shortcuts" && <ShortcutsView root />}

          {path === "/users" && <UserList root />}
          {path === "/organizations" && <OrganizationList />}
          {path === "/puzzleadmins" && <PuzzleAdmins />}
        </div>
        <div className={bottomClass}>
          <Tabs />
        </div>
      </div>
    </div>
  );
};

export default App;
