import React, { useState, useEffect } from "react";
import style from "./style.module.css";
import conditional from "../../utils/conditional";
import { useAppState } from "../../contexts/AppState";
import icons from "../../resources/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faLink,
  faMessage,
  faUserAlt,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

const Tabs = (props) => {
  const tabs = [
    {
      icon: <FontAwesomeIcon icon={faHome} />,
      iconSelected: icons.homeColor,
      name: "Home",
      path: "/",
    },
    {
      icon: <FontAwesomeIcon icon={faMessage} />,
      iconSelected: icons.chatColor,
      name: "Chat",
      path: "/chat",
    },
    {
      icon: <FontAwesomeIcon icon={faUsers} />,
      iconSelected: icons.directorySelected,
      name: "Team",
      path: "/contacts",
    },
    {
      icon: <FontAwesomeIcon icon={faUserAlt} />,
      iconSelected: icons.profileSelected,
      name: "Profile",
      path: "/profile",
    },
    {
      icon: <FontAwesomeIcon icon={faLink} />,
      iconSelected: icons.shortcutSelected,
      name: "Shortcuts",
      path: "/shortcuts",
    },
  ];

  const { path, setPath } = useAppState();

  const wait = (time) =>
    new Promise((resolve) =>
      setTimeout(() => {
        resolve(time);
      }, time)
    );

  const tab = (tab, index) => {
    const selected = path === tab.path;

    const tabClass = conditional("tab", style, { selected });
    const iconClassNormal = conditional("iconState", style, {
      visible: !selected,
    });
    const iconClassSelected = conditional("iconState", style, {
      visible: selected,
    });

    return (
      <div key={index} className={tabClass} onClick={() => setPath(tab.path)}>
        <span
          className={`${
            path === tab.path ? "text-lime-600" : "text-slate-600"
          }`}
        >
          {tab.icon}
        </span>
        <div className={style.name}>{tab.name}</div>
      </div>
    );
  };

  return (
    <div className={style.Tabs}>
      <div className={style.tabs}>{tabs.map(tab)}</div>
    </div>
  );
};

export default Tabs;
