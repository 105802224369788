import React, { useState, useRef, useEffect } from "react";
import style from "./style.module.css";
import icons, { awardIcon } from "../../resources/icons";

import NavigationView from "../ContentView";
import SegmentTabs from "../SegmentTabs";
import { useAppState, useEffectGroup } from "../../contexts/AppState";
import { useTabSwitcher } from "../../utils/tabSwitcher";
import badges from "../../api/badges";
import conditional from "../../utils/conditional";
import useSmoothState from "../../utils/useSmoothState";
import surveys from "../../api/surveys";
import users from "../../api/users";
import groups from "../../api/groups";
import Loader from "../v2/LoaderInline/LoaderInline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const actions = [];

const AddPuzzleAdminView = (props) => {
  const back = async () => {
    props.onBack();
  };

  const [isSaving, setIsSaving] = useState(false);
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [totalPages, setTotalPages] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);

  const [currentPage, setCurrentPage] = useState(1); // New state for current page
  const itemsPerPage = 20; // Adjust this to set items per page

  const [selected, setSelected] = useState(null);
  const [appUsers, setAppUsers] = useState([]);

  const fetchPaginatedUsers = async (group) => {
    setIsLoading(true);
    const results = await users.list(1, search, "65406e8bf9134425b97d7a5c", {
      admin: false,
    });

    if (!results) {
      console.log("error fetching users");
      setIsLoading(false);
      return;
    }

    setAppUsers(results.data);
    setTotalPages(results.pagination.totalPages);
    setTotalUsers(results.pagination.totalUsers);
    setCurrentPage(results.pagination.currentPage);
    setIsLoading(false);
  };
  function handleSearch() {
    if (search.length <= 3) {
      return alert(
        "Type in more than 3 characters - so we can narrow down the search."
      );
    }
    fetchPaginatedUsers();
  }

  useEffect(() => {
    console.log(selected);
  }, [selected]);

  const newUserViewClass = conditional("NewUserView", style, {
    visible: true,
  });

  const suggestion = (data) => (
    <div
      className={style.suggestion}
      onClick={() => {
        setSelected(data);
      }}
    >
      <div className={style.suggestionName}>
        {data.firstName} {data.lastName}
      </div>
      <div className={style.suggestionPreview}>{data.email}</div>
    </div>
  );
  const item = (item, index) => (
    <div className={conditional("item", style, {})} onClick={() => {}}>
      <div className={style.itemContent}>
        <div className={style.itemInfo}>
          <div
            className={style.itemPhoto}
            style={{ backgroundImage: `url(${item.thumbnail})` }}
          />
          <div className={style.itemNameContent}>
            <div className={style.itemName}>
              {item.firstName + " " + item.lastName}
            </div>
            <div className={style.itemPreview}>{item.email}</div>
          </div>
          <div
            className={style.delete}
            style={{ backgroundImage: icons.remove }}
            onClick={() => {
              setSelected(null);
              setSearch("");
            }}
          ></div>
        </div>
      </div>
    </div>
  );

  const handleSetAdmin = async () => {
    setIsSaving(true);
    const result = await groups.put("65406e8bf9134425b97d7a5c", selected._id, {
      admin: true,
    });

    setIsSaving(false);

    props.onBack(true);
  };

  return (
    <div className={newUserViewClass}>
      <div className={style.container}>
        <NavigationView
          title="Add Puzzler"
          modal
          onClose={!props.root ? back : null}
          hideHeader
          persistTitle
          actions={actions}
        >
          <div className="">
            <p className="pl-5 mb-4 text-xs flex gap-2">
              <FontAwesomeIcon
                className="text-sm text-slate-500"
                icon={faInfoCircle}
              />
              PuzzleTip - you can now search by user's first name, last name,
              and email!
            </p>
            <div className={style.profile}>
              {selected ? (
                <div className={style.answerInput}>{item(selected)}</div>
              ) : (
                <div className={style.answerInput}>
                  <div className="flex">
                    <input
                      value={search}
                      className="flex-grow border border-gray-300  outline-none focus:border-lime-600 p-2 text-sm"
                      placeholder={`Start typing...`}
                      disabled={isLoading}
                      onChange={(e) => setSearch(e.target.value)}
                    />

                    <button
                      disabled={isLoading}
                      onClick={handleSearch}
                      className="px-2 flex gap-2 items-center text-xs bg-lime-600 py-0 text-white"
                    >
                      <FontAwesomeIcon icon={faSearch} />
                      Search
                    </button>
                  </div>
                  <div className="mt-5">{isLoading && <Loader text="" />}</div>

                  {appUsers.length > 0 && !isLoading ? (
                    <div
                      className={style.suggestions}
                      style={{
                        display: appUsers.length ? "block" : "none",
                      }}
                    >
                      {appUsers.map(suggestion)}
                    </div>
                  ) : isLoading ? (
                    <></>
                  ) : (
                    <p className="text-xs">
                      Sorry we found no matching users. Maybe try a better
                      query?
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className={style.section}>
            <div className={style.surveyActions}>
              <div
                className={style.surveyAction + " " + style.secondary}
                onClick={back}
              >
                Cancel
              </div>
              {selected && (
                <div className={style.surveyAction} onClick={handleSetAdmin}>
                  {isSaving ? "Please wait..." : "Set as admin"}
                </div>
              )}
            </div>
          </div>
          <div style={{ height: "20px" }} />
        </NavigationView>
      </div>
    </div>
  );
};

export default AddPuzzleAdminView;
