import { component } from "./";

const request = component("");

const getLatestAppVersion = async () => {
  try {
    const result = await request("/getLatestAppVersion", {});
    return result.latestAppVersion;
  } catch (e) {
    return null;
  }
};

export default getLatestAppVersion;
