import { component } from "./";

const request = component("/notes");

const create = async (group, toUser, content, type) => {
  try {
    const results = await request("/create", { group, toUser, content, type });
    return results;
  } catch (e) {
    return null;
  }
};

const list = async (toUser) => {
  try {
    const results = await request("/list", { toUser });
    return results;
  } catch (e) {
    return null;
  }
};

const edit = async (noteId, content) => {
  try {
    const results = await request("/edit", { noteId, content });
    return results;
  } catch (e) {
    return null;
  }
};

const removeNote = async (noteId) => {
  try {
    const results = await request("/delete", { noteId });
    return results;
  } catch (e) {
    return null;
  }
};

export default { create, list, edit, removeNote };
